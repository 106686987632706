import css from "styled-jsx/css";
import { desktop, phone } from "../../../styles/media";

export const styles = css`
  .CustomModal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 301;
    width: 100%;
    background-color: var(--colors-white);
  }
  .CustomModalOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 300;
    overflow-y: auto;
    background-color: rgba(51,51,51,0.45);
    transition: opacity 0.15s ease-out;
  }

  @media ${desktop} {
    .CustomModal {
      width: 675px;
    }
  }
  @media ${phone} {
    .CustomModal {
      margin: 50px 0 0;
    }
  }
`;
