import { ReactNode } from "react";

interface Props {
  dataMarker?: string;
  dataTestId?: string;
  children: ReactNode;
}

const CustomModalBody = (props: Props) => {
  const { children, dataMarker, dataTestId } = props;

  return (
    <div className="CustomModalBody" data-marker-popup={dataMarker} data-testid={dataTestId}>
      {children}
      <style jsx>{`
        .CustomModalBody {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      `}</style>
    </div>
  );
};

export default CustomModalBody;
