import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { selectCartItems } from "src/redux/cart/general/cartSelectors";
import addProductsToCart from "src/redux/cart/general/operations/addProductsToCart";
import { useAppSelector } from "src/redux/hooks";
import { closeModal, switchModal } from "src/redux/modals";
import useRecommendations from "src/swr/useRecommendations";
import { createEanAmountMap } from "src/utils/cart/createEanAmountMap";
import { filterCartItems } from "../../../utils/cart/products";
import { sendAddRecommendedProductsToCart, sendGoToCartButtonClick } from "../helpers/analytics";

const useRecommendationModalProps = () => {
  const dispatch = useDispatch();
  const cartItems = useAppSelector(selectCartItems);
  const excludedEans = cartItems.map(item => item.ean);
  const {
    recommendations,
    recommendationsAreFetching,
    mutateRecommendations,
  } = useRecommendations({ excludedEans });

  useEffect(() => {
    //data should be updated on modal reopening
    if (recommendations.length) {
      mutateRecommendations();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToCart = () => {
    dispatch(switchModal('recommendationsModal', 'cartModal'));
  };

  const handleClose = () => {
    dispatch(closeModal('recommendationsModal'));
  };

  const handleGoToCart = () => {
    sendGoToCartButtonClick();
    goToCart();
  };

  const handleAddToCart = () => {
    const productsWithoutCartItems = filterCartItems(recommendations, cartItems);
    const eanAmountMap = createEanAmountMap(productsWithoutCartItems);

    dispatch(addProductsToCart(
      productsWithoutCartItems,
      eanAmountMap,
      'Recommendations Modal',
    ));
    sendAddRecommendedProductsToCart(productsWithoutCartItems, eanAmountMap);
    goToCart();
  };

  return {
    recommendations,
    loaderIsVisible: recommendationsAreFetching,
    recommendationsAreEmpty: recommendations.length === 0 && !recommendationsAreFetching,
    handleGoToCart,
    handleClose,
    handleAddToCart,
  };
};

export default useRecommendationModalProps;
