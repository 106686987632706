import Image from "next/image";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { sendOpenRecommendationsClick } from "src/components/containers/EmptyCart/helpers/analytics";
import Button from "src/components/ui-kit/Button/Button";
import { openModal } from "src/redux/modals";
import useLocalization from 'src/services/localization/useLocalization';
import { phone } from "src/styles/media";
import { styles } from "./RecommendationsBanner.styles";

const RecommendationsBanner = () => {
  const dispatch = useDispatch();
  const localize = useLocalization();
  const isPhone = useMediaQuery({ query: phone });

  const handleClick = () => {
    dispatch(openModal('recommendationsModal'));
    sendOpenRecommendationsClick('bottom_block_on_main_page');
  };

  const iconSideInPx = !isPhone ? 64 : 44;

  return (
    <div
      className="RecommendationsBanner"
      data-marker='Recommendations banner'
    >
      <div className="RecommendationsBanner__imageWrapper">
        <Image
          src="/i/magic-wand.svg"
          alt='magic wand'
          width={iconSideInPx}
          height={iconSideInPx}
        />
      </div>
      <div className="RecommendationsBanner__content">
        <div className="RecommendationsBanner__title">{localize('recommendations_banner_title')}</div>
        <div className="RecommendationsBanner__description">{localize('recommendations_banner_description')}</div>
        <div className="RecommendationsBanner__button">
          <Button
            onClick={handleClick}
            dataMarkerValue="View recommendations"
            variant="ghost"
          >
            {localize('general.view')}
          </Button>
        </div>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default RecommendationsBanner;
