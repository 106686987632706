import css from 'styled-jsx/css';
import { desktop, phone, tablet_desktop } from '../../../../styles/media';
import typography from '../../../../styles/typography';

export const styles = css`
  .CustomModalHeader {
    display: flex;
    align-items: center;
    min-height: 44px;
    overflow: hidden;
  }
  .CustomModalHeader__title {
    ${typography.h4}
  }
  .CustomModalHeader__close {
    margin-left: auto;
    margin-right: 0;
  }
  .CustomModalHeader__closeButton {
    background: transparent;
    padding: 0;
    border: none;
    outline: none;
    transition: transform 0.2s ease-out;
    color: var(--colors-text);
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    margin-top: 3px;
  }
  .CustomModalHeader__closeButton:hover {
    transform: rotate(90deg);
  }

  @media ${desktop} {
    .CustomModalHeader {
      position: relative;
      z-index: 10;
    }
  }

  @media ${tablet_desktop} {
    .CustomModalHeader {
      padding: 0 16px;
      background-color: var(--colors-lightGrey1);
    }
  }

  @media ${phone} {
    .CustomModalHeader {
      justify-content: center;
      padding: 0 10px;
    }
    .CustomModalHeader__close {
      position: fixed;
      top: 30px;
      right: 8px;
      z-index: 301;
    }
    .CustomModalHeader__title {
      font-size: 18px;
    }
    .CustomModalHeader__closeButton {
      width: 40px;
      height: 40px;
      box-shadow: '0px 2px 8px rgba(0,0,0,0.1)';
      font-size: 12px;
      background-color: var(--colors-white);
      border-radius: var(--border-radius-circle);
    }
  }
`;
