import LoadingBarIndicator from '../../LoadingBarIndicator/LoadingBarIndicator';
import { styles } from './CustomModalHeader.styles';

interface Props {
  onClose(): void;
  title: string | null;
  isLoading?: boolean;
}

const CustomModalHeader = ({ onClose, title, isLoading }: Props) => {
  return (
    <div className='CustomModalHeader'>
      {
        title && (
          <div className='CustomModalHeader__title' data-testid='title'>
            <span>{title}</span>
          </div>
        )
      }
      <div className='CustomModalHeader__close'>
        <button
          type='button'
          className='CustomModalHeader__closeButton icon-close'
          data-marker='Close popup'
          onClick={onClose}
        />
      </div>
      {
        isLoading && <LoadingBarIndicator isUnderContainer />
      }
      <style jsx>{styles}</style>
    </div>
  );
};

export default CustomModalHeader;
