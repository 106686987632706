import { not_phone, phone } from 'src/styles/media';
import css from 'styled-jsx/css';

export const styles = css`
  @media ${not_phone} {
    .RecommendationsModalButtons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .RecommendationsModalButtons__button {
      margin-left: 4px;
      width: 240px;
    }
  }

  @media ${phone} {
    .RecommendationsModalButtons__button {
      margin-bottom: 4px;
    }
  }
`;

