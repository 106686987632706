import { Dispatch } from 'redux';
import { weightMeasureEnum } from 'src/fixtures';
import { restrict } from '../../../../utils/cart/restrict';
import { Product } from '../../../apiTypes';
import { RootState } from '../../../reducers';
import { getSendingToCartItems } from '../../general/helpers/getSendingToCartItems';
import { ServerCartItem } from '../types';
import updateServerCartItemsInState from '../updateServerCartItemsInState';
import updateCartItemsOnServerWithDebounce from './updateCartItemsOnServerWithDebounce';

const addProductsToServerCart = (
  products: Product[],
  eanAmountMap: { [ean: string]: { amount: number } },
  ) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const newServerCartItemsForLocalState = [...state.serverCart.data.items];

  products.forEach((product) => {
    const { ean, currency, price, unit, bundle, quantity } = product;
    const cartItemIndex = newServerCartItemsForLocalState.findIndex((i) => i.ean === ean);
    const productInCart = cartItemIndex !== -1;

    if (productInCart) {
      const max = weightMeasureEnum.properties[unit].limit;
      const limitedAmount = newServerCartItemsForLocalState[cartItemIndex].amount + restrict(
        eanAmountMap[ean].amount,
        unit,
        quantity,
      );

      newServerCartItemsForLocalState[cartItemIndex] = {
        ...newServerCartItemsForLocalState[cartItemIndex],
        amount: limitedAmount > max ? max : limitedAmount,
      };
    } else {
      const limitedAmount = restrict(
        eanAmountMap[ean].amount,
        unit,
        quantity,
      );

      const newCartItem: ServerCartItem = {
        ean,
        amount: limitedAmount,
        currency,
        price,
        unit,
        comments: '',
        bundle,
      };

      newServerCartItemsForLocalState.push(newCartItem);
    }
  });

  dispatch(updateServerCartItemsInState(newServerCartItemsForLocalState));
  const updateItems = products.map((p) => {
    const amount = restrict(eanAmountMap[p.ean].amount, p.unit, p.quantity);

    return { ean: p.ean, amount };
  });
  const cartItemsUpdateData = getSendingToCartItems(updateItems, 'add');
  dispatch(updateCartItemsOnServerWithDebounce(cartItemsUpdateData));
};

export default addProductsToServerCart;
