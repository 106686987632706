import cn from 'classnames';
import * as React from 'react';
import { styles } from './ProductRowPlaceholder.styles';

interface Props {
  small?: boolean;
  testId?: string;
  withAnimation?: boolean;
}

const ProductRowPlaceholder: React.FC<Props> = (props) => {
  const { small, testId, withAnimation } = props;

  if (small) {
    return (
      <div
        className={cn(
          'ProductRowPlaceholder', {
          'ProductRowPlaceholder_withAnimation': withAnimation,
        })}
        data-testid={testId}
      >
        <div className='ProductRowPlaceholder__separator'></div>
        <div className='ProductRowPlaceholder__horizontalPadding_medium'></div>
        <div className='ProductRowPlaceholder__flexWrapper'>
          <div className='ProductRowPlaceholder__MobileItemSidePadding'></div>
          <div className='ProductRowPlaceholder__MobileItemCenterPadding'></div>
          <div className='ProductRowPlaceholder__fullWidthContainer'>
            <div className='ProductRowPlaceholder__MobileItemContentSeparator'></div>
            <div className='ProductRowPlaceholder__MobileItemContentMargin'></div>
          </div>
          <div className='ProductRowPlaceholder__MobileItemSidePadding'></div>
        </div>
        <div className='ProductRowPlaceholder__horizontalPadding_small'></div>
        <div className='ProductRowPlaceholder__flexWrapper_spaceBetween'>
          <div className='ProductRowPlaceholder__MobileItemSidePadding'></div>
          <div className='ProductRowPlaceholder__MobileItemSidePadding'></div>
        </div>
        <div className='ProductRowPlaceholder__horizontalPadding_medium'></div>

        <style jsx>{styles}</style>
      </div>
    );
  }

  return (
    <div
      className={cn(
        'ProductRowPlaceholder', {
        'ProductRowPlaceholder_withAnimation': withAnimation,
      })}
      data-testid={testId}
    >
      <div className='ProductRowPlaceholder__separator'></div>
      <div className='ProductRowPlaceholder__horizontalPadding_medium'></div>
      <div className='ProductRowPlaceholder__flexWrapper'>
        <div className='ProductRowPlaceholder__itemSidePadding'></div>
        <div className='ProductRowPlaceholder__itemFirstCenterPadding'></div>
        <div>
          <div className='ProductRowPlaceholder__itemContentSeparator'></div>
          <div className='ProductRowPlaceholder__itemContentMargin'></div>
        </div>
        <div className='ProductRowPlaceholder__itemSecondCenterPadding'></div>
        <div className='ProductRowPlaceholder__itemSidePadding'></div>
        <div className='ProductRowPlaceholder__itemRightPadding'></div>
      </div>
      <div className='ProductRowPlaceholder__horizontalPadding_medium'></div>

      <style jsx>{styles}</style>
    </div>
  );
};

export default ProductRowPlaceholder;
