import ProductRow from "src/components/views/ProductRow/ProductRow";
import { Product } from "src/redux/apiTypes";
import useRecommendationsListProps from "../../hooks/useRecommendationsListProps";

interface Props {
  recommendations: Product[];
}

const RecommendationsList = (props: Props) => {
  const { recommendations } = props;
  const productRows = useRecommendationsListProps(recommendations);

  return (
    <div className="RecommendationsList">
      {productRows.map(props => (
        <div className="RecommendationsList__item" key={`recommendation ${props.title}`}>
          <ProductRow  {...props}  />
        </div>
      ))}
    </div>
  );
};

export default RecommendationsList;
