import css from 'styled-jsx/css';
import { phone } from '../../../styles/media';

export const styles = css`
    .ProductRowPlaceholder {
      position: relative;
    }

    .ProductRowPlaceholder_withAnimation {
      background-color: #eee;
      background: linear-gradient(to right, #f5f5f5 5%, #eee 20%, #f5f5f5 35%);
      animation: placeholderShimmer 3s linear 0s infinite normal forwards;
    }

    .ProductRowPlaceholder__flexWrapper {
      display: flex;
    }

    .ProductRowPlaceholder__flexWrapper_spaceBetween {
      display: flex;
      justify-content: space-between;
    }

    .ProductRowPlaceholder__horizontalPadding_small {
      width: 100%;
      height: 8px;
      background: #fff;
    }

    .ProductRowPlaceholder__separator {
      width: 100%;
      height: 1px;
      background: #efefef;
    }

    .ProductRowPlaceholder__horizontalPadding_medium {
      width: 100%;
      height: 12px;
      background: #fff;
    }

    .ProductRowPlaceholder__itemSidePadding {
      width: 16px;
      height: 50px;
      background: #fff;
    }

    .ProductRowPlaceholder__itemRightPadding {
      width: 16px;
      height: 50px;
      background: #fff;
      position: absolute;
      right: 0;
    }

    .ProductRowPlaceholder__itemFirstCenterPadding {
      width: 16px;
      height: 50px;
      background: #fff;
      margin-left: 50px;
    }

    .ProductRowPlaceholder__itemContentSeparator {
      width: 360px;
      height: 6px;
      background: #fff;
      margin-top: 32px;
    }

    .ProductRowPlaceholder__itemContentMargin {
      width: 70px;
      height: 12px;
      background: #fff;
      float: right;
    }

    .ProductRowPlaceholder__MobileItemSidePadding {
      width: 10px;
      height: 48px;
      background: #fff;
    }

    .ProductRowPlaceholder__MobileItemCenterPadding {
      width: 10px;
      height: 48px;
      background: #fff;
      margin-left: 50px;
    }

    .ProductRowPlaceholder__fullWidthContainer {
      width: 100%;
    }

    .ProductRowPlaceholder__MobileItemContentSeparator {
      width: 100%;
      height: 5px;
      background: #fff;
      margin-top: 30px;
    }

    .ProductRowPlaceholder__MobileItemContentMargin {
      width: 40px;
      height: 13px;
      background: #fff;
      float: right;
    }

    @media ${phone} {
      .ProductRowPlaceholder_withAnimation {
        height: auto;
        animation: placeholderShimmer 4s linear 0s infinite normal;
      }
    }

    @keyframes placeholderShimmer {
      0% {
        background-position: -1000px 0;
      }
      100% {
        background-position: 1000px 0;
      }
    }
  `;
