import Button from "src/components/ui-kit/Button/Button";
import useLocalization from 'src/services/localization/useLocalization';
import { styles } from "./RecommendationsModalButtons.styles";

interface Props {
  onGoToCart(): void;
  onAddToCart(): void;
}

const RecommendationsModalButtons = (props: Props) => {
  const { onGoToCart, onAddToCart } = props;
  const localize = useLocalization();

  return (
    <div className="RecommendationsModalButtons">
      <div className="RecommendationsModalButtons__button">
        <Button
          variant="secondary"
          dataMarkerValue="go to cart"
          fullWidth
          onClick={onGoToCart}
        >
          {localize('go_to_cart')}
        </Button>
      </div>

      <div className="RecommendationsModalButtons__button">
        <Button
          dataMarkerValue="add to cart"
          fullWidth
          onClick={onAddToCart}
        >
          {localize('add_all_to_cart')}
        </Button>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default RecommendationsModalButtons;
