import { ReactNode } from "react";

interface Props {
  dataMarker?: string;
  children: ReactNode;
}

const CustomModalContent = (props: Props) => {
  const { children, dataMarker } = props;

  return (
    <div className="CustomModalContent" data-marker={dataMarker}>
      {children}
      <style jsx>{`
        .CustomModalContent {
          flex: 1 1 100%;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
        }
      `}</style>
    </div>
  );
};

export default CustomModalContent;
