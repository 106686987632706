import { phone } from "src/styles/media";
import typography from "src/styles/typography";
import css from "styled-jsx/css";

export const styles = css`
  .RecommendationsEmpty {
    text-align: center;
    width: 360px;
    max-width: 100%;
    margin: 128px auto 0;
  }
  .RecommendationsEmpty__image {
    width: 220px;
    margin-bottom: 10px;
  }
  .RecommendationsEmpty__title {
    ${typography.h2}
    margin-bottom: 8px;
  }
  .RecommendationsEmpty__description {
    ${typography.h5}
    color: var(--colors-doveGray);
    max-width: 308px;
    margin: 0 auto 16px;
  }

  @media ${phone} {
    .RecommendationsEmpty {
      margin-top: 80px;
    }
    .RecommendationsEmpty__title {
      ${typography.h3}
    }
    .RecommendationsEmpty__description {
      font-size: 14px;
    }
    .RecommendationsEmpty__image {
      width: 174px;
    }
  }
`;
