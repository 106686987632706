import { Fragment, ReactNode, useEffect, useRef } from "react";
import { disableBodyScroll, enableBodyScroll } from "src/utils/system/scrollLock";
import { styles } from "./CustomModal.styles";

interface CustomModalProps {
  children: ReactNode;
  onCloseModal(): void;
}

const CustomModal = (props: CustomModalProps) => {
  const { children, onCloseModal } = props;
  const handleCloseRef = useRef(onCloseModal);

  const handleEscapePress = (event: KeyboardEvent) => {
    const existsAnotherOpenedModal = document.getElementsByClassName('GeneralModal__body').length;

    if (event.key !== 'Escape') {
      return;
    }

    event.preventDefault();

    if (!existsAnotherOpenedModal) {
      handleCloseRef.current();
    }
  };

  useEffect(() => {
    disableBodyScroll();
    document.addEventListener('keydown', handleEscapePress);

    return () => {
      enableBodyScroll();
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, []);

  return (
    <Fragment>
      <div className="CustomModal" data-marker-popup="Chains Sidebar">
        {children}
      </div>

      <div className="CustomModalOverlay" data-testid='overlay' onClick={onCloseModal} />
      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default CustomModal;
