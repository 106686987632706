import { Fragment } from "react";
import CustomModal from "src/components/views/CustomModal/CustomModal";
import CustomModalBody from "src/components/views/CustomModal/CustomModalBody/CustomModalBody";
import CustomModalContent from "src/components/views/CustomModal/CustomModalContent/CustomModalContent";
import CustomModalFooter from "src/components/views/CustomModal/CustomModalFooter/CustomModalFooter";
import CustomModalHeader from "src/components/views/CustomModal/CustomModalHeader/CustomModalHeader";
import ProductsListPlaceholder from "src/components/views/Placeholders/ProductsListPlaceholder/ProductsListPlaceholder";
import useLocalization from 'src/services/localization/useLocalization';
import useRecommendationModalProps from "../../hooks/useRecommendationsModalProps";
import RecommendationsEmpty from "../RecommendationsEmpty/RecommendationsEmpty";
import RecommendationsList from "../RecommendationsList/RecommendationsList";
import RecommendationsModalButtons from "../RecommendationsModalButtons/RecommendationsModalButtons";

const RecommendationsModal = () => {
  const localize = useLocalization();
  const {
    recommendations,
    loaderIsVisible,
    recommendationsAreEmpty,
    handleClose,
    handleGoToCart,
    handleAddToCart,
  } = useRecommendationModalProps();

  return (
    <CustomModal onCloseModal={handleClose}>
      <CustomModalBody>
        <CustomModalHeader
          title={localize('my_recommendations')}
          onClose={handleClose}
          isLoading={loaderIsVisible}
        />
        {
          loaderIsVisible
            ? <ProductsListPlaceholder />
            : recommendationsAreEmpty
              ? <RecommendationsEmpty />
              : (
                <Fragment>
                  <CustomModalContent dataMarker='Suggestion_items_list'>
                    <RecommendationsList recommendations={recommendations} />
                  </CustomModalContent>
                  <CustomModalFooter>
                    <RecommendationsModalButtons
                      onAddToCart={handleAddToCart}
                      onGoToCart={handleGoToCart}
                    />
                  </CustomModalFooter>
                </Fragment>
              )
        }

      </CustomModalBody>

    </CustomModal>
  );
};

export default RecommendationsModal;
