import { phone } from "src/styles/media";
import typography from "src/styles/typography";
import css from "styled-jsx/css";

export const styles = css`
  .RecommendationsBanner {
    padding: 16px;
    display: flex;
    border-radius: var(--border-radius-block);
    background-color: var(--colors-white);
  }

  .RecommendationsBanner__imageWrapper {
    margin-right: 16px;
  }

  .RecommendationsBanner__title {
    ${typography.h4}
    margin-bottom: 2px;
  }

  .RecommendationsBanner__description {
    ${typography.text}
    margin-bottom: 5px;
  }

  @media ${phone} {
    .RecommendationsBanner {
      padding: 12px;
    }

    .RecommendationsBanner__imageWrapper {
      margin-right: 10px;
    }

    .RecommendationsBanner__title {
      ${typography.h6}
      margin-bottom: 0;
    }
    .RecommendationsBanner__description {
      margin-bottom: 0;
    }
  }
`;
