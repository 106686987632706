import useLocalization from 'src/services/localization/useLocalization';
import { styles } from "./RecommendationsEmpty.styles";

const RecommendationsEmpty = () => {
  const localize = useLocalization();

  return (
    <div className="RecommendationsEmpty">
      <img className="RecommendationsEmpty__image" src='/i/empty-recommendations.png' alt='Empty recommendations' />
      <div className="RecommendationsEmpty__title">{localize('almost_ready')}</div>
      <div className="RecommendationsEmpty__description">{localize('recommendations_empty_description')}</div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default RecommendationsEmpty;
