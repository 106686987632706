import { ReactNode } from "react";
import { phone } from "src/styles/media";

const CustomModalFooter = ({ children }: { children: ReactNode }) => {
  return (
    <div className="CustomModalFooter">
      {children}
      <style jsx>{`
        .CustomModalFooter {
          background-color: var(--colors-lightGrey1);
          padding: 16px;
        }

        @media ${phone} {
          .CustomModalFooter {
            padding: 16px 12px;
          }
        }
      `}</style>
    </div>
  );
};

export default CustomModalFooter;
