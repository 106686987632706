import ProductRowPlaceholder from '../../ProductRowPlaceholder/ProductRowPlaceholder';
import Responsive from '../../Responsive/Responsive';
import styles from './ProductsListPlaceholder.styles';

const ProductsListPlaceholder = () => {
  return (
    <div className='ProductsListPlaceholder' data-testid='products_list_placeholder'>
      <Responsive tabletDesktop>
        <div className="PlaceholderBody">
          <div className='PlaceholderBody__horizontalPadding_large'></div>
          <div className='PlaceholderBody__flexWrapper'>
            <div className='PlaceholderBody__headerSidePadding'></div>
            <div className='PlaceholderBody__headerCenterPadding'></div>
            <div className='PlaceholderBody__headerSidePadding'></div>
          </div>
          <div className='PlaceholderBody__horizontalPadding_small'></div>

          {new Array(9).fill(null).map((_, key) => <ProductRowPlaceholder key={key} />)}

          <div className='PlaceholderBody PlaceholderBody__footerWrapper'>
            <div className='PlaceholderBody__footerHorizontalPadding_small'></div>
            <div className='PlaceholderBody__flexWrapper'>
              <div className='PlaceholderBody__footerSidePadding'></div>
              <div className='PlaceholderBody__footerCenterPadding'></div>
              <div className='PlaceholderBody__footerSidePadding'></div>
            </div>
            <div className='PlaceholderBody__footerHorizontalPadding_large'></div>
            <div className='PlaceholderBody__flexWrapper'>
              <div className='PlaceholderBody__footerSidePadding'></div>
              <div className='PlaceholderBody__footerCenterPadding_second'></div>
              <div className='PlaceholderBody__footerSidePadding'></div>
            </div>
            <div className='PlaceholderBody__footerHorizontalPadding_large'></div>
          </div>
        </div>
      </Responsive>

      <Responsive phone>
        <div className="PlaceholderBody">
          <div className='PlaceholderBody__horizontalPadding_large'></div>
          <div className='PlaceholderBody__flexWrapper_spaceBetween'>
            <div className='PlaceholderBody__MobileHeaderSidePadding_large'></div>
            <div className='PlaceholderBody__MobileHeaderSidePadding_large'></div>
          </div>
          <div className='PlaceholderBody__horizontalPadding_medium'></div>
          <div className='PlaceholderBody__flexWrapper_spaceBetween'>
            <div className='PlaceholderBody__MobileHeaderSidePadding_medium'></div>
            <div className='PlaceholderBody__MobileHeaderSidePadding_medium'></div>
          </div>
          <div className='PlaceholderBody__horizontalPadding_large'></div>
          <div className='PlaceholderBody__horizontalPadding_large'></div>
          <div className='PlaceholderBody__flexWrapper'>
            <div className='PlaceholderBody__MobileHeaderSidePadding_small'></div>
            <div className='PlaceholderBody__MobileHeaderCenterPadding'></div>
            <div className='PlaceholderBody__MobileHeaderSidePadding_small'></div>
          </div>
          <div className='PlaceholderBody__horizontalPadding_large'></div>

          {new Array(4).fill(null).map((_, key) => <ProductRowPlaceholder small key={key} />)}

        </div>
      </Responsive>
      <style jsx>{styles}</style>
    </div>
  );
};

export default ProductsListPlaceholder;
