import css from 'styled-jsx/css';
import { phone } from '../../../../styles/media';

const styles = css`
  .PlaceholderBody {
    width: 100%;
    height: 100vh;
    background-color: #eee;
    background: linear-gradient(to right,#f5f5f5 5%,#eee 20%,#f5f5f5 35%);
    animation: placeholderShimmer 3s linear 0s infinite normal forwards;
  }

  .PlaceholderBody__flexWrapper {
    display: flex;
  }

  .PlaceholderBody__flexWrapper_spaceBetween {
    display: flex;
    justify-content: space-between;
  }

  .PlaceholderBody__horizontalPadding_large {
    width: 100%;
    height: 14px;
    background: #fff;
  }

  .PlaceholderBody__horizontalPadding_medium {
    width: 100%;
    height: 12px;
    background: #fff;
  }

  .PlaceholderBody__horizontalPadding_small {
    width: 100%;
    height: 8px;
    background: #fff;
  }

  .PlaceholderBody__headerSidePadding {
    width: 16px;
    height: 16px;
    background: #fff;
  }

  .PlaceholderBody__headerCenterPadding {
    margin: 0 140px 0 120px;
    flex-grow: 1;
    height: 16px;
    background: #fff;
  }

  .PlaceholderBody__footerWrapper {
    width: 100%;
    height: 158px;
    position: absolute;
    bottom: 0;
  }

  .PlaceholderBody__footerHorizontalPadding_small {
    width: 100%;
    height: 16px;
    background: #F6F6F6;
  }

  .PlaceholderBody__footerHorizontalPadding_large {
    width: 100%;
    height: 24px;
    background: #F6F6F6;
  }

  .PlaceholderBody__footerSidePadding {
    width: 16px;
    height: 54px;
    background: #F6F6F6;
  }

  .PlaceholderBody__footerCenterPadding {
    margin: 0 130px 0 130px;
    flex-grow: 1;
    height: 54px;
    background: #F6F6F6;
  }

  .PlaceholderBody__footerCenterPadding_second {
    margin: 0 130px 0 320px;
    flex-grow: 1;
    height: 54px;
    background: #F6F6F6;
  }

  .PlaceholderBody__MobileHeaderSidePadding_small {
    width: 10px;
    height: 16px;
    background: #fff;
  }

  .PlaceholderBody__MobileHeaderSidePadding_medium {
    width: 10px;
    height: 40px;
    background: #fff;
  }

  .PlaceholderBody__MobileHeaderSidePadding_large {
    width: 10px;
    height: 100px;
    background: #fff;
  }

  .PlaceholderBody__MobileHeaderCenterPadding {
    margin: 0 140px 0 120px;
    flex-grow: 1;
    height: 16px;
    background: #fff;
  }

  @media ${phone} {
    .PlaceholderBody {
      height: auto;
      animation: placeholderShimmer 4s linear 0s infinite normal;
    }
  }

  @keyframes placeholderShimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
}
`;

export default styles;
